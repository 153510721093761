<template>
  <v-card
    class="mx-auto"
    width=100%
    height=100%
    flat
    tile
  >
     <v-toolbar
      color="indigo"
      dark
    >
      <v-btn @click="onback"  icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-1">{{name}}</v-toolbar-title>
      <v-spacer></v-spacer>
      
    </v-toolbar>
 
    <v-card class="mx-auto">                                 
                    <v-card-text class="pt-4">

                        <v-form ref="form">
                            <v-text-field
                            label="Username (*)"
                            v-model="username"                           
                            required                            
                            ></v-text-field>
                            <v-text-field
                            label="Password (*)"
                            v-model="password"
                            type="password"
                            required
                            ></v-text-field>
                            <v-text-field
                            label="Confirm Password (*)"
                            v-model="confirmpassword"
                            type="password"
                            required
                            ></v-text-field>
                        </v-form>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn @click="submit" class="green white--text">Register</v-btn>
                       
                    </v-card-actions>
                <v-spacer></v-spacer>
                </v-card>

         <v-dialog max-width="400" v-model="showdialog" persistent>
          <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{dialogtitle}}
          </v-card-title>
  
          <v-spacer></v-spacer>
          <v-card-text>
 
          </v-card-text>
          <v-card-text>
            {{dialogmessage}}
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="hidedialog"
            >
              {{dialogbttile}}
            </v-btn>
          </v-card-actions>
        </v-card>
     </v-dialog>

  <v-spacer></v-spacer>

  </v-card>
</template>

<script>

import { Base64 } from 'js-base64'
export default {
  
    data: () => ({
        xsrf:'',
        name: 'User Register', 
        username: '',
        password: '',
        confirmpassword:'',
        dialogtitle:'',
        dialogmessage:'',
        dialogbttile:'',
        showdialog:false,
    }),
    created() {
       
              var xsrf = this.getCookie("_xsrf")
             if(xsrf!=""){
                var xsrflist  = xsrf.split("|");
              
               // console.log("xsrflist ->",xsrflist[0])
                this.xsrf = Base64.decode(xsrflist[0])
                //console.log("xsrf ->",this.xsrf)
             } 
      //
    },
    mounted(){
       // console.log("Register mounted   ");
 
    },
    destroyed() {
       // console.log("Register destroyed   ");
    },
    methods: {
      getCookie: function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1);
          if (c.indexOf(name) != -1){
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },
        hidedialog(){
                this.showdialog = false;
        },
        submit () {
                    
          
          if(this.username =='' ||this.password =='' ||this.confirmpassword ==''){
                    this.dialogtitle="Warning";
                    this.dialogmessage="User name or Password is empty  !";
                    this.dialogbttile="Close";
                    this.showdialog = true;
                    return;
           }else if(this.password ==this.confirmpassword ){
               console.log('register=>',this.username); 
           }else{
                    this.dialogtitle="Warning";
                    this.dialogmessage="The password you entered is not the same as the confirmation password !";
                    this.dialogbttile="Close";
                    this.showdialog = true;

           }
                var  UserInfo = {user:this.username,pwd:this.password};
                
                this.axios.post('/api/userregister',UserInfo,{
                 headers: {
                  'Access-Control-Allow-Origin': "*",
                  'X-Xsrftoken':this.xsrf,
                  'Rhrtc-Sign':"fdmFADFOEJFff232ddfdfd3SDFF"
                 }
                })
                .then(res=>{
                    console.log('res=>',res.data); 
                    
                    if(res.data.state==200){
                                      
                                      this.$router.push("login")    

                    }else{
       
                    this.dialogtitle="Warning";
                    this.dialogmessage=res.data.message+"  error  code "+res.data.state;
                    this.dialogbttile="Close";
                    this.showdialog = true;
                    return;
                
                    }
                    
                            
                })
                .catch(error=>{
                       console.log('error=>',error);
                    this.dialogtitle="Warning";
                    this.dialogmessage="net work  error "+error;
                    this.dialogbttile="Close";
                    this.showdialog = true;
                    return;
                })

               

           

               
        },
       onback: function(){
            
           this.$router.push("login")  
      }
  
    },
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-card {
    text-align: center;

}

.v-card__title {
    justify-content: center;
}

.v-btn {
    width: 100%;
}

.link-color {
    color: #d38841;
    text-decoration: none;
}



.v-card__actions {
    display: block;
}
</style>